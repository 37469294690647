
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, message, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import Settings from '../../dependencies/custom/settings';


const Login = (props) => {
    const valuesStore = ValuesStore();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    useMemo(() => {

    }, []);

    async function login() {
        setBtnLoading(true);
        await symLogin(username, password, navigate, '../admin/dashboard');
        setBtnLoading(false);
    }

    async function symLogin(username, password, navigate, redirectUrl) { 
        const url = `${Settings.backend}/admin_login`;       
        const res = await utils.request('post',url , null, {username,password},
            {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${username}:${password}`)}`
            });
        if (res.status === 'Ok') { 
            const fullname = res.data.name;
            localStorage.setItem('fullname',fullname);           
            const token = res?.token;            
            utils.setCookie('token', token, 0.5);            
            if (redirectUrl) {
                navigate(redirectUrl);
            }
        } else {
            message.error(res.msg);
        }
    }

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3 mx-auto mb-2 borderx mdb-colorx darken-3 special-colorx rounded p-3' style={{ marginTop: '15%' }}>
                        <Space className='w-100' direction='vertical'>
                            <div className='d-flex justify-content-center rounded' >
                                <img className='mx-auto mb-3' width={190} height={180} src={`${Settings.logo}/resources/sysimg/fav.png`} />
                            </div>
                            <Input onPressEnter={e => login()} className='mb-2x rounded' type='text' prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Username' value={username} onChange={e => setUsername(e.target.value)} />
                            <Input.Password onPressEnter={e => login()} className='rounded' prefix={<LockOutlined className="site-form-item-icon" />} placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
                            <Space wrap>
                                <Button loading={btnLoading} className='border-0 text-whitex amber accent-4 text-white' onClick={e => login()}><i className='fas fa-unlock me-2' />Login</Button>
                                {/* <Button style={{background:'rgb(160 106 1)',color:'white'}} className='border-0 text-whitex' onClick={e => navigate('../../init_psd_recovery')}><i className='fas fa-unlock me-2' />Forgot Password</Button> */}
                            </Space>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Login);