
import { useMemo, useState } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Tabs,
    message,
    List,
    Avatar,
    DatePicker,
    Tag,
    Space
} from "antd";
import {
    ArrowUpOutlined,
    RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import useChart from '../../hooks/chart';
import useTable from "../../hooks/table";


import utils from "../../dependencies/custom/react-utilities";

import Settings from "../../dependencies/custom/settings";
import { useNavigate } from "react-router-dom";

function Home() {
    const { Title, Text } = Typography;
    const [count, setCount] = useState([]);
    const chart = useChart();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [boreholeIncome, setBoreHoldeIncome] = useState();
    const [incomeExp, setIncomeExp] = useState();
    const [expenses, setExpenses] = useState();
    const [sales, setSales] = useState();
    const [debtors, setDebtors] = useState([]);
    const navigate = useNavigate();

    const productTable = useTable({
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true,
        },
        filters: { ...filters },
        filterTypes: { ...filterTypes }
    }, null, null, null, null, 'id', null, null);

    const loyalCustomersTable = useTable({
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true,
        },
        filters: { ...filters },
        filterTypes: { ...filterTypes }
    }, null, null, null, null, 'id', null, null);

    const topSuppliersTable = useTable({
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true,
        },
        filters: { ...filters },
        filterTypes: { ...filterTypes }
    }, null, null, null, null, 'id', null, null);



    async function getIncomeExpenses(startDate, endDate) {
        const url = `${Settings.backend}/get_income_exp`;
        let res = await utils.requestWithReauth('post', url, null, { startDate, endDate });

        if (res.status != 'Ok') return;

        const expenses = res.result.expenses;
        const income = res.result.income;

        let finalIncome = {};
        income?.forEach(v => {
            const date = Object.keys(v)[0];
            finalIncome[date] = parseFloat(v[date]);
        });
        let finalCost = {};
        for (let key in expenses) {
            const cost = expenses[key];
            cost.forEach((v) => {
                const date = Object.keys(v)[0];
                if (finalCost[date]) {
                    finalCost[date] += parseFloat(v[date]);
                } else {
                    finalCost[date] = 0;
                    finalCost[date] += parseFloat(v[date]);
                }
            })
        }
        let graphIncome = [];
        let graphExpenses = [];
        let labels = [];
        for (let key in finalIncome) {
            graphExpenses.push(finalCost[key]);
            graphIncome.push(finalIncome[key]);
            labels.push(key);
        }
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Income',
                    data: graphIncome,
                    // backgroundColor: ['#3e5c9a','#e98340','#f6c04c']
                    backgroundColor: '#3e5c9a'
                },
                {
                    label: 'Expenses',
                    data: graphExpenses,
                    // backgroundColor: ['#3e5c9a','#e98340','#f6c04c']
                    backgroundColor: '#e98340'
                }
            ]
        };
        setIncomeExp(data);
    }

    async function getExpenses() {
        const url = `${Settings.backend}/get_expenses`;
        let res = await utils.requestWithReauth('post', url, null, {});
        if (res.status != 'Ok') return;
        const summary = res.result.summary;
        const sortedSummary = Object.entries(summary)?.sort(function (a, b) {
            return b[1] - a[1];
        });
        let labels = [];
        let graphData = [];
        for (let i = 0; i <= 2; i++) {
            const key = sortedSummary[i][0];
            const value = sortedSummary[i][1];
            labels.push(key);
            graphData.push(value);
        }
        const data = {
            labels,
            datasets: [
                {
                    label: 'The Label',
                    data: graphData,
                    backgroundColor: ['#3e5c9a', '#e98340', '#f6c04c']
                }
            ]
        };
        setExpenses(data);
    }

    async function getBoreHoleIncome(startDate, endDate) {
        const url = `${Settings.backend}/get_borehole_income`;
        let res = await utils.requestWithReauth('post', url, null, { startDate, endDate });
        if (res.status != 'Ok') return;
        const { summary } = res.result;
        let finalIncome = {};
        summary?.forEach(v => {
            const date = Object.keys(v)[0];
            finalIncome[date] = parseFloat(v[date]);
        });

        let graphIncome = [];
        let labels = [];
        for (let key in finalIncome) {
            graphIncome.push(finalIncome[key]);
            labels.push(key);
        }
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Borehole Income',
                    data: graphIncome,
                    backgroundColor: '#3e5c9a'
                }
            ]
        };
        setBoreHoldeIncome(data);
    }


    async function getTabsData() {
        const url = `${Settings.backend}/get_tabs_data`;
        let res = await utils.requestWithReauth('post', url, null, {});
        // console.log(res);
        // return;
        if (res.status != 'Ok') return;
        const { loyalCustomers, productStock, topSuppliers, boreholeIncome } = res.result;
        const productSummary = productStock.summary;
        let products = [];
        for (let key in productSummary) {
            products.push({ name: key, stock: productSummary[key], id: key });
        }

        productTable.setData(products);


        const tps = topSuppliers.map((v, i) => ({ id: `${i}_supplier`, name: v.name, supplies: v.number_supplied }));
        topSuppliersTable.setData(tps);

        const lc = loyalCustomers.map((v, i) => ({ id: `${i}_supplier`, name: v.customer_name, sales: v.sales_amt, phone_no: v.phone_no }));
        loyalCustomersTable.setData(lc);
    }

    const productColumn = ([
        {
            title: 'Product',
            dataIndex: 'name',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            render: (value, record) => {
               return Math.round(value);
            },
        },
        {
            title: 'Available',
            dataIndex: 'available',
            render: (value, record) => {
                return <Tag className='rounded' color={record.stock > 0 ? 'green-inverse' : 'red-inverse'}>
                    {record.stock > 0 ? 'Yes' : 'No'}
                </Tag>
            },
        }
    ]);

    const suppliersColumn = ([
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Number of Supplies',
            dataIndex: 'supplies'
        },
        {
            title: '',
            dataIndex: 'available',
            render: (value, record) => {
                return <Tag className='rounded' color="green-inverse"> <ArrowUpOutlined /></Tag>
            },
        }
    ]);

    const customerColumn = ([
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Sales Made',
            dataIndex: 'sales'
        },
        {
            title: '',
            dataIndex: 'phone_no',
            render: (value, record) => {                
                return <Tooltip title='Click to view sales'><Tag onClick={e => navigate(`../sales?phone_no=${record['phone_no']}`)} className='rounded' color="blue-inverse"> <i className="fas fa-user" /></Tag></Tooltip>
            },
        }
    ]);

    async function getSales() {
        const url = `${Settings.backend}/get_sales`;
        let res = await utils.requestWithReauth('post', url, null, {});

        if (res.status != 'Ok') return;
        const summary = res.result;

        let labels = [];
        let graphData = [];

        for (let i = 0; i <= 2; i++) {
            if (summary[i]) {
                const key = summary[i]['product_item'];
                const value = summary[i]['expected_amt'];
                labels.push(key);
                graphData.push(value);
            }
        }
        const data = {
            labels,
            datasets: [
                {
                    label: 'Sales',
                    data: graphData,
                    backgroundColor: ['#3e5c9a', '#e98340', '#f6c04c']
                }
            ]
        };
        setSales(data);
    }

    async function getNumbers() {
        const url = `${Settings.backend}/get_numbers`;
        let res = await utils.requestWithReauth('post', url, null, {});
        if (res.status != 'Ok') return;
        const result = res.result;
        setCount([
            {
                today: "Blocks on Site",
                title: Math.round(result.blocksOnSite)?.toString()?.toLocaleString("en-US"),
                persent: "",
                icon: <i className="fas fa-hand-holding-usd fa-2x" />,
                bnb: "bnb2",
            },
            {
                today: "Blks Undelivered",
                title: Math.round(result.undelivered)?.toString()?.toLocaleString("en-US"),
                persent: "",
                icon: <i className="fas fa-luggage-cart fa-2x" />,
                bnb: "bnb2",
            },
            {
                today: "Blks Delivered",
                title: Math.round(result.delivered)?.toString()?.toLocaleString("en-US"),
                persent: "",
                icon: <i className="fas fa-bus fa-2x" />,
                bnb: "bnb2",
            },
            {
                today: "Total Debt",
                title: `${Math.round(result.debt)?.toString()?.toLocaleString("en-US")}`,
                persent: "",
                icon: <i className="fas fa-credit-card fa-2x" />,
                bnb: "bnb2",
            }
        ]);
    }

    const tabItems = [
        {
            key: '1',
            label: 'Products',
            children: productTable.table,
        },
        {
            key: '2',
            label: 'Top Suppliers',
            children: topSuppliersTable.table,
        },
        {
            key: '3',
            label: 'Borehole Income',
            children: <div className="container">
                <div className="d-flex justify-content-end">
                    <DatePicker.RangePicker bordered={false} picker={'month'} onChange={e => getNewBoreholeInc(e)} />
                </div>
                {chart.BarChart(boreholeIncome)}
            </div>,
        },
        {
            key: '4',
            label: 'Loyal Customers',
            children: loyalCustomersTable.table,
        },
    ];

    async function getDebtors() {
        const url = `${Settings.backend}/get_debtors`;
        let res = await utils.requestWithReauth('post', url, null, {});
        if (res.status != 'Ok') return;
        const { debtors } = res.result;
        setDebtors(debtors);
    }


    useMemo(() => {
        productTable.setColumns(productColumn);
        loyalCustomersTable.setColumns(customerColumn);
        topSuppliersTable.setColumns(suppliersColumn);
        getNumbers();
        getIncomeExpenses();
        getExpenses();
        getSales();
        getTabsData();
        getDebtors();
        getBoreHoleIncome();
        chart.setPlugins([chart.allPlugins.ChartDataLabels]);
    }, []);


    function getNewIE(e) {
        const startDate = e[0]?.$y + '-' + (e[0]?.$M + 1);
        const endDate = e[1]?.$y + '-' + (e[1]?.$M + 1);//+2 because php always reduce the month by one
        getIncomeExpenses(startDate, endDate);
    }

    function getNewBoreholeInc(e) {
        const startDate = e[0]?.$y + '-' + (e[0]?.$M + 1);
        const endDate = e[1]?.$y + '-' + (e[1]?.$M + 1);//+2 because php always reduce the month by one
        getBoreHoleIncome(startDate, endDate);
    }


    return (
        <>
            <div className="layout-content">
                {/* <div className="row rowgap-vbox">
                    {count.map((c, index) => (
                        <div className="col-md-3 mb-24" key={index}>
                            <Card bordered={false} className="criclebox">
                                <div className="number">
                                    <div className="row g-2 align-items-center">
                                        <div className="col-md-9">
                                            <span>{c.today}</span>
                                            <Title level={3}>
                                                {c.title} <small className={c.bnb}>{c.persent}</small>
                                            </Title>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="icon-box">{c.icon}</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}

                </div> */}

                <div className="row mb-4">
                    <div className="col-md-8">
                        <Card
                            bordered={false}
                            className="criclebox h-full tablespace"
                            title="Income and Expense"
                            extra={
                                <>
                                    <DatePicker.RangePicker bordered={false} picker={'month'} onChange={e => getNewIE(e)} />
                                </>
                            }
                        >
                            {chart.BarChart(incomeExp)}
                        </Card>
                    </div>

                    <div className="col-md-4">
                        <Card
                            bordered={false}
                            className="criclebox h-full tablespace"
                            title="Top Expenses"
                            extra={<></>}
                        >
                            {chart.PieChart(expenses)}
                        </Card>
                    </div>

                    {/* <div className="col-md-3">
                        <Card
                            bordered={false}
                            className="criclebox h-full tablespace"
                            title="Top Sales"
                            extra={<></>}
                        >
                            {chart.DoughnutChart(sales)}
                        </Card>
                    </div> */}
                </div>

                {/* <div className="row">
                    <div className="col-md-8 mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full p-1 tablespace border-0 mb-24x">
                            <Tabs defaultActiveKey="1" items={tabItems} />
                        </Card>
                    </div>
                    <div className="col-md-4 mb-24">
                        <Card bordered={false} className="criclebox h-full p-0 tablespace border-0 mb-24x" title="Debtors & Defaulters">
                            <div className="timeline-box h-scrolling-wrapper" style={{ height: '350px' }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={debtors}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                                                title={<Space>
                                                    <label>{item.customer_name}</label>
                                                    <label>({item.phone_no})</label>
                                                </Space>}
                                                description={<label className="text-danger">{item.bal}</label>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Card>
                    </div>
                </div> */}

                {/* <Row gutter={[24, 0]}>
                    <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Row gutter>
                                <Col
                                    xs={24}
                                    md={12}
                                    sm={24}
                                    lg={12}
                                    xl={14}
                                    className="mobile-24"
                                >
                                    <div className="h-full col-content p-20">
                    di                    <div className="ant-muse">
                                            <Text>Built by developers</Text>
                                            <Title level={5}>Muse Dashboard for Ant Design</Title>
                                            <Paragraph className="lastweek mb-36">
                                                From colors, cards, typography to complex elements, you
                                                will find the full documentation.
                                            </Paragraph>
                                        </div>
                                        <div className="card-footer">
                                            <a className="icon-move-right" href="#pablo">
                                                Read More
                                                {<RightOutlined />}
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={24}
                                    md={12}
                                    sm={24}
                                    lg={12}
                                    xl={10}
                                    className="col-img"
                                >
                                    <div className="ant-cret text-right">
                                        <img src={card} alt="" className="border10" />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
                        <Card bordered={false} className="criclebox card-info-2 h-full">
                            <div className="gradent h-full col-content">
                                <div className="card-content">
                                    <Title level={5}>Work with the best</Title>
                                    <p>
                                        Wealth creation is an evolutionarily recent positive-sum
                                        game. It is all about who take the opportunity first.
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <a className="icon-move-right" href="#pablo">
                                        Read More
                                        <RightOutlined />
                                    </a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row> */}

            </div>
        </>
    );
}

export default Home;
